import React, {createContext, useCallback, useEffect, useState} from 'react';

let logoutTimer;
let localExpirationTime;

const FirebaseAuthContext = createContext({
  token: '',
  isLoggedIn: false,
  /**
   * Handle set login state
   * @param {String} token Firebase ID token
   * @param {String} expirationTime ISO string of expiration time
   */
  login: (token, expirationTime) => {},
  // logout: () => {},
  getRemainingTime: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjExpirationTime - currentTime;
  return remainingDuration;
};

const retrieveStoredToken = () => {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return;

  const storedToken = localStorage.getItem('token');
  const storeExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(storeExpirationDate);

  if (remainingTime <= 1000) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    return null;
  }

  localExpirationTime = storeExpirationDate;

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

export const FirebaseAuthContextProvider = ({children}) => {
  const tokenData = retrieveStoredToken();
  let initialToken;

  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [tokenState, setToken] = useState(initialToken);
  const userIsLoggedIn = !!tokenState;

  /**
   * handle logout
   */
  const logoutHandler = useCallback(() => {
    const isSSR = typeof window === 'undefined';
    if (isSSR) return;

    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  /**
   * Handle set login state
   * @param {String} token Firebase ID token
   * @param {String} expirationTime ISO string of expiration time
   */
  const loginHandler = (token, expirationTime) => {
    const isSSR = typeof window === 'undefined';
    if (isSSR) return;

    setToken(token);
    localStorage.setItem('token', token);
    localStorage.setItem('expirationTime', expirationTime);
    localExpirationTime = expirationTime;

    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const getRemainingTime = () => calculateRemainingTime(localExpirationTime);

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: tokenState,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    // logout: logoutHandler,
    getRemainingTime,
  };

  return (
    <FirebaseAuthContext.Provider value={contextValue}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthContext;
