import React from 'react';

function IconWebDesign({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 10.5 6 C 10.3105 6 10.124082 6.0104805 9.9394531 6.0292969 C 9.571481 6.0667987 9.2141905 6.1413291 8.8710938 6.2480469 C 7.1472189 6.7842455 5.7842455 8.1472189 5.2480469 9.8710938 C 5.1413291 10.21419 5.0667987 10.571481 5.0292969 10.939453 C 5.0292305 10.940097 5.0293631 10.940762 5.0292969 10.941406 C 5.0106094 11.125412 5 11.311158 5 11.5 L 5 13.5 L 5 36.5 C 5 39.519774 7.4802259 42 10.5 42 L 37.5 42 C 40.519774 42 43 39.519774 43 36.5 L 43 13.5 L 43 11.5 C 43 11.310603 42.989737 11.124126 42.970703 10.939453 C 42.933201 10.571481 42.858671 10.21419 42.751953 9.8710938 C 42.215754 8.1472189 40.852781 6.7842455 39.128906 6.2480469 C 38.78581 6.1413291 38.428519 6.0667987 38.060547 6.0292969 C 38.059905 6.0292315 38.059235 6.029362 38.058594 6.0292969 C 37.874547 6.0103939 37.688736 6 37.5 6 L 10.5 6 z M 10.5 9 C 11.328 9 12 9.672 12 10.5 C 12 11.328 11.328 12 10.5 12 C 9.672 12 9 11.328 9 10.5 C 9 9.672 9.672 9 10.5 9 z M 15.5 9 C 16.328 9 17 9.672 17 10.5 C 17 11.328 16.328 12 15.5 12 C 14.672 12 14 11.328 14 10.5 C 14 9.672 14.672 9 15.5 9 z M 21.5 9 L 37.5 9 C 38.328 9 39 9.672 39 10.5 C 39 11.328 38.328 12 37.5 12 L 21.5 12 C 20.672 12 20 11.328 20 10.5 C 20 9.672 20.672 9 21.5 9 z M 8 15 L 40 15 L 40 36.5 C 40 37.898226 38.898226 39 37.5 39 L 10.5 39 C 9.1017741 39 8 37.898226 8 36.5 L 8 15 z M 12.5 18 A 1.50015 1.50015 0 0 0 11 19.5 L 11 34.5 A 1.50015 1.50015 0 0 0 12.5 36 L 20.5 36 A 1.50015 1.50015 0 0 0 22 34.5 L 22 19.5 A 1.50015 1.50015 0 0 0 20.5 18 L 12.5 18 z M 26.5 18 A 1.50015 1.50015 0 1 0 26.5 21 L 35.5 21 A 1.50015 1.50015 0 1 0 35.5 18 L 26.5 18 z M 14 21 L 19 21 L 19 33 L 14 33 L 14 21 z M 26.5 23 A 1.50015 1.50015 0 1 0 26.5 26 L 35.5 26 A 1.50015 1.50015 0 1 0 35.5 23 L 26.5 23 z M 26.5 28 A 1.50015 1.50015 0 1 0 26.5 31 L 35.5 31 A 1.50015 1.50015 0 1 0 35.5 28 L 26.5 28 z M 26.5 33 A 1.50015 1.50015 0 1 0 26.5 36 L 31.5 36 A 1.50015 1.50015 0 1 0 31.5 33 L 26.5 33 z" />
    </svg>
  );
}

export default IconWebDesign;
