import React, {memo} from 'react';
import MenuItem from './MenuItem';

/**
 *
 * @param {Object} props - Component's props
 * @param {Array} props.menu - Array Object of Menu Item
 * @param {String} props.className - className of Component
 * @param {boolean} props.isSticky - Variable to check sticky state
 * @returns
 */

const DesktopNavigation = ({menu, className, isSticky}) => {
  return (
    <nav className={`${className ?? ''}`}>
      <ul className="flex flex-row items-center">
        {menu.map(
          (mainItem) =>
            !mainItem.parentId && (
              <MenuItem menu={mainItem} key={mainItem.id} isSticky={isSticky} />
            )
        )}
      </ul>
    </nav>
  );
};

export default memo(DesktopNavigation);
