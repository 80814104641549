import React from 'react';

import IconCloud from './IconCloud';
import IconBusiness from './IconBusiness';
import IconDev from './IconDev';
import IconShoppingCart from './IconShoppingCart';
import IconSeo from './IconSeo';
import IconProgramming from './IconProgramming';
import IconDevOps from './IconDevOps';
import IconDigitalWorkplace from './IconDigitalWorkplace';
import IconERPSystem from './IconERPSystem';
import IconCRM from './IconCRM';
import IconOffice365 from './IconOffice365';
import IconGoogle from './IconGoogle';
import IconWebDesign from './IconWebDesign';
import IconMobileDev from './IconMobileDev';
import IconDomainName from './IconDomainName';
import IconServer from './IconServer';
import IconWebApp from './IconWebApp';
import IconBlockchain from './IconBlockchain';

export const MenuIcons = [
  {
    url: '/dich-vu/giai-phap-doanh-nghiep',
    Icon: ({className = ''}) => {
      return <IconBusiness className={className} />;
    },
  },

  {
    url: '/dich-vu/giai-phap-lap-trinh',
    Icon: ({className = ''}) => {
      return <IconDev className={className} />;
    },
  },
  {
    url: '/dich-vu/hosting-domain-cloud',
    Icon: ({className = ''}) => {
      return <IconShoppingCart className={className} />;
    },
  },
  {
    url: '/dich-vu/dich-vu-seo',
    Icon: ({className = ''}) => {
      return <IconSeo className={className} />;
    },
  },
  {
    url: '/dich-vu/phan-mem-theo-yeu-cau',
    Icon: ({className = ''}) => {
      return <IconProgramming className={className} />;
    },
  },
  {
    url: '/dich-vu/devops',
    Icon: ({className = ''}) => {
      return <IconDevOps className={className} />;
    },
  },
  {
    url: '/dich-vu/giai-phap-digital-workplace',
    Icon: ({className = ''}) => {
      return <IconDigitalWorkplace className={className} />;
    },
  },
  {
    url: '/dich-vu/giai-phap-erp',
    Icon: ({className = ''}) => {
      return <IconERPSystem className={className} />;
    },
  },
  {
    url: '/dich-vu/giai-phap-crm',
    Icon: ({className = ''}) => {
      return <IconCRM className={className} />;
    },
  },
  {
    url: '/dich-vu/giai-phap-microsoft',
    Icon: ({className = ''}) => {
      return <IconOffice365 className={className} />;
    },
  },
  {
    url: '/dich-vu/giai-phap-google',
    Icon: ({className = ''}) => {
      return <IconGoogle className={className} />;
    },
  },
  {
    url: '/dich-vu/thiet-ke-website',
    Icon: ({className = ''}) => {
      return <IconWebDesign className={className} />;
    },
  },
  {
    url: '/dich-vu/lap-trinh-web-app',
    Icon: ({className = ''}) => {
      return <IconWebApp className={className} />;
    },
  },
  {
    url: '/dich-vu/lap-trinh-ung-dung-di-dong',
    Icon: ({className = ''}) => {
      return <IconMobileDev className={className} />;
    },
  },
  {
    url: '/dich-vu/block-chain',
    Icon: ({className = ''}) => {
      return <IconBlockchain className={className} />;
    },
  },
  {
    url: '/dich-vu/dich-vu-ten-mien',
    Icon: ({className = ''}) => {
      return <IconDomainName className={className} />;
    },
  },
  {
    url: '/dich-vu/dich-vu-hosting',
    Icon: ({className = ''}) => {
      return <IconServer className={className} />;
    },
  },
  {
    url: '/dich-vu/dich-vu-cloud',
    Icon: ({className = ''}) => {
      return <IconCloud className={className} />;
    },
  },
];
