import React, {createContext, useState} from 'react';

const PostContext = createContext({
  // Show/hide floating Toc button
  isShowTocBtn: false,
  setIsShowTocBtn: () => {},
  // Open/close overlay toc
  isOpenOverlayToc: false,
  setIsOpenOverlayToc: () => {},
});

export default PostContext;

export const PostContextProvider = ({children}) => {
  const [isShowTocBtn, setIsShowTocBtn] = useState(false);
  const [isOpenOverlayToc, setIsOpenOverlayToc] = useState(false);

  const contextValue = {
    isShowTocBtn,
    setIsShowTocBtn,
    isOpenOverlayToc,
    setIsOpenOverlayToc,
  };

  return (
    <PostContext.Provider value={contextValue}>{children}</PostContext.Provider>
  );
};
