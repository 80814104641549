import React from 'react';

function IconDigitalWorkplace({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 9.5 6 C 7.5850452 6 6 7.5850452 6 9.5 L 6 19.5 C 6 21.414955 7.5850452 23 9.5 23 L 13 23 L 13 26 L 9.5 26 C 7.0324991 26 5 28.032499 5 30.5 L 5 41.5 A 1.50015 1.50015 0 1 0 8 41.5 L 8 30.5 C 8 29.653501 8.6535009 29 9.5 29 L 14.253906 29 A 1.50015 1.50015 0 0 0 14.740234 29 L 20.253906 29 A 1.50015 1.50015 0 0 0 20.740234 29 L 26 29 L 26 34.253906 A 1.50015 1.50015 0 0 0 26 34.740234 L 26 41.5 A 1.50015 1.50015 0 0 0 27.5 43 L 41.5 43 A 1.50015 1.50015 0 0 0 43 41.5 L 43 34.746094 A 1.50015 1.50015 0 0 0 43 34.259766 L 43 30.5 C 43 28.032499 40.967501 26 38.5 26 L 27.746094 26 A 1.50015 1.50015 0 0 0 27.259766 26 L 22 26 L 22 23 L 25.5 23 C 27.414955 23 29 21.414955 29 19.5 L 29 9.5 C 29 7.5850452 27.414955 6 25.5 6 L 9.5 6 z M 9.5 9 L 25.5 9 C 25.795045 9 26 9.2049548 26 9.5 L 26 19.5 C 26 19.795045 25.795045 20 25.5 20 L 20.746094 20 A 1.50015 1.50015 0 0 0 20.259766 20 L 14.746094 20 A 1.50015 1.50015 0 0 0 14.259766 20 L 9.5 20 C 9.2049548 20 9 19.795045 9 19.5 L 9 9.5 C 9 9.2049548 9.2049548 9 9.5 9 z M 33.5 16 A 1.50015 1.50015 0 1 0 33.5 19 L 40.5 19 A 1.50015 1.50015 0 1 0 40.5 16 L 33.5 16 z M 33.5 21 A 1.50015 1.50015 0 1 0 33.5 24 L 40.5 24 A 1.50015 1.50015 0 1 0 40.5 21 L 33.5 21 z M 16 23 L 19 23 L 19 26 L 16 26 L 16 23 z M 29 29 L 38.5 29 C 39.346499 29 40 29.653501 40 30.5 L 40 33 L 29 33 L 29 29 z M 29 36 L 40 36 L 40 40 L 29 40 L 29 36 z" />
    </svg>
  );
}

export default IconDigitalWorkplace;
