import React from 'react';

function IconSeo({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 37.994141 4 C 35.247707 4 32.990234 6.2594255 32.990234 9.0058594 L 32.990234 39 C 32.990234 41.746434 35.247707 44.005859 37.994141 44.005859 C 40.740575 44.005859 43 41.746434 43 39 L 43 9.0058594 C 43 6.2594255 40.740575 4 37.994141 4 z M 17.5 5 A 1.50015 1.50015 0 1 0 17.5 8 L 20.679688 8 L 4.5039062 22.378906 A 1.50015 1.50015 0 1 0 6.4960938 24.621094 L 23 9.9511719 L 23 13.5 A 1.50015 1.50015 0 1 0 26 13.5 L 26 6.5 A 1.50015 1.50015 0 0 0 24.5 5 L 17.5 5 z M 37.994141 7 C 39.119707 7 40 7.8802933 40 9.0058594 L 40 39 C 40 40.125566 39.119707 41.005859 37.994141 41.005859 C 36.868575 41.005859 35.990234 40.125566 35.990234 39 L 35.990234 9.0058594 C 35.990234 7.8802933 36.868575 7 37.994141 7 z M 24 19 C 21.253566 19 18.994141 21.259425 18.994141 24.005859 L 18.994141 39 C 18.994141 41.746434 21.253566 44.005859 24 44.005859 C 26.746434 44.005859 29.005859 41.746434 29.005859 39 L 29.005859 24.005859 C 29.005859 21.259425 26.746434 19 24 19 z M 24 22 C 25.125566 22 26.005859 22.880293 26.005859 24.005859 L 26.005859 39 C 26.005859 40.125566 25.125566 41.005859 24 41.005859 C 22.874434 41.005859 21.994141 40.125566 21.994141 39 L 21.994141 24.005859 C 21.994141 22.880293 22.874434 22 24 22 z M 9.9941406 27.994141 C 7.2477067 27.994141 4.9902344 30.253566 4.9902344 33 L 4.9902344 39 C 4.9902344 41.746434 7.2477067 44.005859 9.9941406 44.005859 C 12.740575 44.005859 15 41.746434 15 39 L 15 33 C 15 30.253566 12.740575 27.994141 9.9941406 27.994141 z M 9.9941406 30.994141 C 11.119707 30.994141 12 31.874434 12 33 L 12 39 C 12 40.125566 11.119707 41.005859 9.9941406 41.005859 C 8.8685745 41.005859 7.9902344 40.125566 7.9902344 39 L 7.9902344 33 C 7.9902344 31.874434 8.8685745 30.994141 9.9941406 30.994141 z" />
    </svg>
  );
}

export default IconSeo;
