import {BiSearch} from '@react-icons/all-files/bi/BiSearch';
import React from 'react';
import {accessibleOnClick} from '../../utils/helperFunctions';

/**
 * @param {Object} props - Component's props
 * @param {string} props.className - className of Search Button
 * @param {string} props.searchButtonClassName - Class Name of Search Button, searchButtonClassName change when user scroll
 * @param {()=>{}} props.onClick - Handle on mouse click
 * @returns
 */
const HeaderSearchButton = ({className, onClick, searchButtonClassName}) => {
  return (
    <div
      className={`${className ?? ''} cursor-pointer`}
      {...accessibleOnClick(onClick, null, 'Open Search')}
    >
      <BiSearch size={24} className={`${searchButtonClassName}`} />
    </div>
  );
};
export default HeaderSearchButton;
