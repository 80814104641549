import React from 'react';

function IconServer({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 13.5 4 C 10.480226 4 8 6.4802259 8 9.5 L 8 38.5 C 8 41.519774 10.480226 44 13.5 44 L 34.5 44 C 37.519774 44 40 41.519774 40 38.5 L 40 9.5 C 40 6.4802259 37.519774 4 34.5 4 L 13.5 4 z M 13.5 7 L 34.5 7 C 35.898226 7 37 8.1017741 37 9.5 L 37 38.5 C 37 39.898226 35.898226 41 34.5 41 L 13.5 41 C 12.101774 41 11 39.898226 11 38.5 L 11 9.5 C 11 8.1017741 12.101774 7 13.5 7 z M 15.5 10 A 1.50015 1.50015 0 0 0 14 11.5 L 14 25.5 A 1.50015 1.50015 0 0 0 15.5 27 L 32.5 27 A 1.50015 1.50015 0 0 0 34 25.5 L 34 11.5 A 1.50015 1.50015 0 0 0 32.5 10 L 15.5 10 z M 17 13 L 31 13 L 31 17 L 17 17 L 17 13 z M 17 20 L 31 20 L 31 24 L 17 24 L 17 20 z M 24 31 A 3 3 0 0 0 24 37 A 3 3 0 0 0 24 31 z" />
    </svg>
  );
}

export default IconServer;
