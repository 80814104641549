import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import {useMenuQuery} from '../../hooks/useMenuQuery';
import DesktopNavigation from '../Navigation/DesktopNavigation';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import IconLogo from '../Icon/IconLogo';
import ThemeContext from '../../store/theme-context';
import {MenuIcons} from '../Icon/IconNavigationBarConfig';
import AppConfig from '../../config/appConfig';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import HeaderSearchButton from './HeaderSearchButton';
import HeaderMobileMenuButton from './HeaderMobileMenuButton';
import useGetSticky from '../../hooks/useGetSticky';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/**
 * Header Component contain the navigation bar
 * @param {Object} props
 * @param {string} props.headerHeight
 * @param {()=>{}} props.onSearchClicked
 * @returns
 */
const Header = ({headerHeight = 'h-[70px] lg:h-[80px]', onSearchClicked}) => {
  // const isSSR = typeof window == 'undefined';

  const {menu} = useMenuQuery();
  const themeContext = useContext(ThemeContext);
  let windowDimension = useWindowDimensions();
  let isSticky = useGetSticky(AppConfig.navStickyHeight ?? 100);

  let bgClassName = '';
  let bgLogoClassName = '';
  let ctaClassName = '';
  let searchBtnClassName = '';
  let shadowClassName = '';
  let mobileMenuBtnClassName = '';

  let blurEffect =
    'before:backdrop-blur-lg before:absolute before:w-full before:h-full before:-z-10'; // use before to avoid bug on chrome

  // Change style when nav changed or sticky
  if (themeContext.navTransparent) {
    if (isSticky) {
      bgClassName = `bg-white/80 ${blurEffect}`;
      ctaClassName = 'border-black text-black';
      searchBtnClassName = 'fill-black';
      bgLogoClassName = 'fill-primary';
      mobileMenuBtnClassName = 'text-black';
      shadowClassName = 'shadow-card-layout';
    } else {
      bgClassName = 'bg-transparent';
      ctaClassName = 'border-neutral-200 text-white';
      searchBtnClassName = 'fill-white';
      bgLogoClassName = 'fill-white';
      mobileMenuBtnClassName = 'text-white';
    }
  } else {
    bgClassName = `bg-transparent ${blurEffect}`;
    ctaClassName = 'border-black text-black';
    searchBtnClassName = 'fill-black';
    bgLogoClassName = 'fill-primary';
    mobileMenuBtnClassName = 'text-black';

    if (isSticky) {
      bgClassName = `bg-white/80 ${blurEffect}`;
      shadowClassName = 'shadow-card-layout';
    } else {
      shadowClassName = '';
    }
  }

  return (
    <header
      className={`fixed top-0 z-[10] grid w-full auto-cols-fr grid-cols-[1fr_auto_1fr] px-4 md:px-5 lg:px-6 ${headerHeight} ${shadowClassName} ${bgClassName} ${TailwindTransitions.default[200]}`}
    >
      {/* Col-1 */}
      <div className="flex w-fit flex-row items-center justify-start">
        <UniversalLink to="/" className="" aria-label="Logo">
          <IconLogo className={`${bgLogoClassName} h-[24px] lg:h-[30px]`} />
        </UniversalLink>
      </div>
      {/* Col-2 */}
      <div className="flex flex-row items-center justify-start">
        {/* Render Desktop Nav on PCs */}
        <DesktopNavigation
          menu={mapIconUrl(menu.menuItems.nodes)}
          className="hidden h-full lg:flex lg:flex-row"
          isSticky={isSticky}
        />
      </div>
      {/* Col-3*/}
      <div className="flex flex-row items-center justify-end gap-5 lg:gap-8">
        {/* CTAs */}
        {/* <p
          className={`${ctaClassName} mx-3 hidden cursor-pointer flex-row border-b-2 py-2 font-sans font-medium uppercase transition-all duration-500 lg:flex`}
        >
          Tư vấn ngay
        </p> */}

        <HeaderSearchButton
          className="p-2"
          onClick={onSearchClicked}
          searchButtonClassName={searchBtnClassName}
        />

        <HeaderMobileMenuButton className={mobileMenuBtnClassName} />
      </div>
    </header>
  );
};

export default memo(Header);

// mapIcon
function mapIconUrl(menu) {
  let result = menu.map((menuItem) => {
    if (menuItem.childItems?.nodes?.length > 0) {
      menuItem.childItems.nodes = mapIconUrl(menuItem.childItems.nodes);
    }

    // Add Icon to Object
    // let filterResult = MenuIcons.filter((icon) => icon.url === menuItem.uri);

    // // Not Icon for Home Page
    // let icon = filterResult[0]?.icon ?? '';
    return {
      ...menuItem,
      Icon: MenuIcons.filter((icon) => icon.url === menuItem.uri)[0]?.Icon,
    };
  });
  return result;
}
