import React from 'react';

function IconBusiness({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 18.5 6 C 16.585045 6 15 7.5850452 15 9.5 L 15 12 L 7.5 12 C 5.5850452 12 4 13.585045 4 15.5 L 4 39.5 C 4 41.414955 5.5850452 43 7.5 43 L 40.5 43 C 42.414955 43 44 41.414955 44 39.5 L 44 15.5 C 44 13.585045 42.414955 12 40.5 12 L 33 12 L 33 9.5 C 33 7.5850452 31.414955 6 29.5 6 L 18.5 6 z M 18.5 9 L 29.5 9 C 29.795045 9 30 9.2049548 30 9.5 L 30 12 L 18 12 L 18 9.5 C 18 9.2049548 18.204955 9 18.5 9 z M 7.5 15 L 40.5 15 C 40.795045 15 41 15.204955 41 15.5 L 41 29 L 27 29 L 27 28.5 C 27 27.672 26.328 27 25.5 27 L 22.5 27 C 21.672 27 21 27.672 21 28.5 L 21 29 L 7 29 L 7 15.5 C 7 15.204955 7.2049548 15 7.5 15 z M 7 32 L 21 32 L 21 32.5 C 21 33.328 21.672 34 22.5 34 L 25.5 34 C 26.328 34 27 33.328 27 32.5 L 27 32 L 41 32 L 41 39.5 C 41 39.795045 40.795045 40 40.5 40 L 7.5 40 C 7.2049548 40 7 39.795045 7 39.5 L 7 32 z" />
    </svg>
  );
}

export default IconBusiness;
