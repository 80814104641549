import React, {memo} from 'react';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import {MenuDropdownTypes} from '../../_consts/menu-const';
import IconWrapper from '../Wrapper/IconWrapper';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import {LazyMotion, domAnimation, m} from 'framer-motion';

/**
 *
 * @param {Object} props
 * @param {Object} props.menuItems Menu Items
 * @param {string} props.menuType Type of this dropdown
 * @param {()=>{}} props.onItemClick Handler when an item is clicked
 * @returns
 */
const MenuItemDropdown = ({menuItems, menuType, onItemClick = () => {}}) => {
  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {type: 'spring', stiffness: 200, damping: 15},
    },
    closed: {opacity: 0, y: -10, transition: {duration: 0.35}},
  };

  let customCardEffect = 'fixed top-[5.5rem] z-0 bg-white/80 p-4';
  let itemClass =
    'rounded-md p-2 hover:bg-primary hover:fill-white hover:text-white';
  let renderMenu = '';
  switch (menuType) {
    case MenuDropdownTypes.list.col:
      renderMenu = (
        <m.div
          className={`${TailwindTransitions.default[200]} ${customCardEffect} fixed left-20 right-0 top-[5.2rem] m-auto flex  w-fit flex-col gap-3 rounded-lg border border-solid border-white/30 shadow-card-layout shadow-neutral-500/10 backdrop-blur-xl`}
          variants={{
            open: {
              clipPath: 'inset(-10% -10% -10% -10%)',
              y: 1,
              opacity: 1,
              transition: {
                type: 'spring',
                bounce: 0.25,
                duration: 0.01,
                delayChildren: 0,
                staggerChildren: 0,
              },
            },
            closed: {
              clipPath: 'inset(0% 0% 100% 0%)',
              y: 0,
              opacity: 0,
              transition: {
                type: 'spring',
                bounce: 0,
                duration: 0.35,
              },
            },
          }}
        >
          {menuItems.map((item) => (
            <m.span
              className="text-md w-full px-2"
              variants={itemVariants}
              key={item.id}
            >
              <UniversalLink
                to={item.uri}
                className={`${TailwindTransitions.default[100]} ${itemClass} inline-block w-full`}
                onClick={onItemClick}
              >
                {item.label}
              </UniversalLink>
            </m.span>
          ))}
        </m.div>
      );
      break;

    case MenuDropdownTypes.mega.col:
      renderMenu = (
        <m.div
          className={`${TailwindTransitions.default[200]} ${customCardEffect} fixed left-0 right-0 top-[5.2rem] m-auto grid w-[58rem] grid-cols-3 gap-10 rounded-lg border border-solid border-white/30 shadow-card-layout shadow-neutral-500/10 backdrop-blur-xl`}
          variants={{
            open: {
              clipPath: 'inset(-10% -10% -10% -10%)',
              opacity: 1,
              y: 1,
              transition: {
                type: 'spring',
                bounce: 0.25,
                duration: 0.01,

                delayChildren: 0,
                staggerChildren: 0,
              },
            },
            closed: {
              clipPath: 'inset(0% 0% 100% 0%)',

              y: 0,
              opacity: 0,
              transition: {
                type: 'spring',
                bounce: 0,
                duration: 0.35,
              },
            },
          }}
        >
          {menuItems.map((item) => (
            <div key={item.id} className="flex flex-col">
              <m.div variants={itemVariants} className={`flex px-2`}>
                {item?.uri?.length > 0 && item?.uri !== '#' ? (
                  <>
                    <UniversalLink
                      to={item.uri}
                      className={`text-md p-2 font-bold hover:fill-primary hover:text-primary`}
                      onClick={onItemClick}
                    >
                      <span className="flex flex-row">
                        <IconWrapper IconItem={item.Icon} className={'w-4'} />
                        <span className="text-md px-2 font-bold">
                          {item.label}
                        </span>
                      </span>
                    </UniversalLink>
                  </>
                ) : (
                  <span className="flex flex-row">
                    <IconWrapper IconItem={item.Icon} className={'w-4'} />
                    <h4 className="text-md px-2 font-bold">{item.label}</h4>
                  </span>
                )}
              </m.div>

              {item?.childItems?.nodes?.map((item) => (
                <UniversalLink
                  to={item.uri}
                  key={item.id}
                  className={`${TailwindTransitions.default[100]} ${itemClass} wrapper flex`}
                  onClick={onItemClick}
                >
                  <m.span className="flex flex-row " variants={itemVariants}>
                    <IconWrapper IconItem={item.Icon} className={'w-4'} />

                    <span className="text-md px-2">{item.label}</span>
                  </m.span>
                </UniversalLink>
              ))}
            </div>
          ))}
        </m.div>
      );
      break;

    default:
      break;
  }

  return (
    <LazyMotion features={domAnimation} strict>
      {renderMenu}
    </LazyMotion>
  );
};

export default memo(MenuItemDropdown);
