import React, {StrictMode} from 'react';
import Layout from './src/components/Layout/Layout';
import {LazyMotion, domAnimation} from 'framer-motion';
import {ThemeContextProvider} from './src/store/theme-context';
import {LoadingContextProvider} from './src/store/loading-context';
import {OverlayMenuProvider} from './src/store/overlay-menu-context';
import {FirebaseAuthContextProvider} from './src/store/firebase-auth-context';
import {PostContextProvider} from './src/store/post-context';

// Create shared components for wrapPageElement API of Gatsby SSR and Gatsby Browser
export const wrapPageElement = ({element, props}) => {
  return (
    <StrictMode>
      <Layout {...props}>
        {element}

        {/* Script Area */}
      </Layout>
    </StrictMode>
  );
};

export const wrapRootElement = ({element}) => {
  return (
    <ThemeContextProvider>
      <LoadingContextProvider>
        <OverlayMenuProvider>
          <FirebaseAuthContextProvider>
            <PostContextProvider>{element}</PostContextProvider>
          </FirebaseAuthContextProvider>
        </OverlayMenuProvider>
      </LoadingContextProvider>
    </ThemeContextProvider>
  );
};
