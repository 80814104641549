import {useEffect, useState} from 'react';

/**
 *
 * @param {number} stickyHeight Sticky height in px
 * @param {(newStickyState)=>{}} handler Handler function which triggered when sticky state change. Have 1 parameter is new sticky state
 * @returns current sticky state
 */
const useGetSticky = (stickyHeight, handler = () => {}) => {
  const isSSR = typeof window == 'undefined';

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (isSSR) return;

    const checkSticky = () => {
      const newStickyState = window.scrollY >= stickyHeight;
      // check value change before set state to avoid unnecessary re-render
      setIsSticky(newStickyState);
      handler(newStickyState);
    };

    window.addEventListener('scroll', checkSticky);

    return () => {
      window.removeEventListener('scroll', checkSticky);
    };
  }, [setIsSticky, isSticky, isSSR, handler, stickyHeight]);

  return isSticky;
};

export default useGetSticky;
