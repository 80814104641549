export const TailwindTransitions = {
  default: {
    100: 'transition-all duration-100',
    200: 'transition-all duration-200',
    300: 'transition-all duration-300',
  },
  textDecoration: {
    underline: {
      leftToRight: {
        color_primary: {
          '40%':
            'lg:bg-gradient-to-r lg:from-primary/30 lg:to-primary/30 lg:bg-[length:0%_40%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out hover:lg:bg-[length:100%_40%] hover:lg:bg-left-bottom group-hover:lg:bg-[length:100%_40%] group-hover:lg:bg-left-bottom',
        },
        color_gray_500: {
          '10%':
            'lg:bg-gradient-to-r lg:from-gray-500/30 lg:to-gray-500/30 lg:bg-[length:0%_10%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out hover:lg:bg-[length:100%_10%] hover:lg:bg-left-bottom group-hover:lg:bg-[length:100%_10%] group-hover:lg:bg-left-bottom',
          '20%':
            'lg:bg-gradient-to-r lg:from-gray-500/30 lg:to-gray-500/30 lg:bg-[length:0%_20%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out hover:lg:bg-[length:100%_20%] hover:lg:bg-left-bottom group-hover:lg:bg-[length:100%_20%] group-hover:lg:bg-left-bottom',
          '30%':
            'lg:bg-gradient-to-r lg:from-gray-500/30 lg:to-gray-500/30 lg:bg-[length:0%_30%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out hover:lg:bg-[length:100%_30%] hover:lg:bg-left-bottom group-hover:lg:bg-[length:100%_30%] group-hover:lg:bg-left-bottom',
          '40%':
            'lg:bg-gradient-to-r lg:from-gray-500/30 lg:to-gray-500/30 lg:bg-[length:0%_40%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out hover:lg:bg-[length:100%_40%] hover:lg:bg-left-bottom group-hover:lg:bg-[length:100%_40%] group-hover:lg:bg-left-bottom',
        },
      },

      hoverFromLeftFullSize:
        "relative text-neutral-800 no-underline before:absolute before:bottom-0 before:left-0 before:block before:h-[2px] before:w-full before:origin-top-left before:scale-x-0 before:bg-neutral-800 before:transition-transform before:duration-150 before:ease-in before:content-[''] hover:text-neutral-800 hover:before:scale-x-100",
    },
    centerLine: {
      leftToRight:
        'relative no-underline before:absolute before:bottom-[9px] before:left-[-30px] before:block before:h-[2px] before:w-[24px] before:origin-top-left before:scale-x-0 before:bg-neutral-800 before:transition-transform before:duration-300 before:ease-in before:content-[""] hover:before:scale-x-100',
    },
  },
  form: {
    field: {
      default:
        'border-b-[1px] border-opacity-25 bg-transparent placeholder-gray-500 font-normal  outline-[0.5px] outline-transparent transition-all duration-300 ease-in focus:rounded-sm focus:border-transparent focus:pl-3 focus:outline-primary/80',
    },
  },
};
