/**
 * Get file host from URL
 * @param {*} url file source url. Eg: `https://abc.com/file/file-a.zip`
 * @returns file host. Eg: `https://abc.com/file/`
 */
const getFileHost = (url) => {
  const regex = /^http.*\//g;
  return url.match(regex)[0];
};

/**
 * Add suffix to file name
 * @param {String} fileName file name and file type. Eg: `file-name.type`
 * @param {String} suffix suffix. Eg: `-wp`
 * @returns file name with suffix. Eg: `file-name-wp.type`
 */
const addFileNameSuffix = (fileName, suffix) => {
  if (!fileName) return;

  let regex = /(\.[\w\d?=_-]+)$/i;
  return fileName.replace(regex, `${suffix}$1`);
};

const filterKeyEnter = (handler) => {
  return (e) => {
    if (e.keyCode === 13) {
      // Enter key in ASCII
      handler(e);
    }
  };
};

/**
 *
 * @param {()=>{}} handler Handler function
 * @param {Number} tabIndex tabindex number. Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
 * @param {string} label aria-label value
 * @returns
 */
function accessibleOnClick(handler, tabIndex, label) {
  return {
    role: 'button',
    tabIndex: tabIndex || 0,
    onKeyDown: filterKeyEnter(handler),
    onClick: handler,
    'aria-label': label,
  };
}

/**
 * Group objects in an array by key
 * @param {Object[]} arr Array of objects
 * @param {string} property property to be grouped
 * @returns an object that contains grouped data
 */
const groupObjByKeyToObj = (arr, property) => {
  return arr.reduce(function (prev, curr) {
    if (!prev[curr[property]]) {
      prev[curr[property]] = [];
    }
    prev[curr[property]].push(curr);
    return prev;
  }, {});
};

/**
 *
 * @param {Object[]} array The search array
 * @param {string} propertyName Name of the property you want to search for
 * @param {any} propertyValue The value of the property you want to search for
 * @returns Object if found, null if not
 */
const findElementWithPropertyName = (array, propertyName, propertyValue) => {
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (
      element.hasOwnProperty(propertyName) &&
      element[propertyName] === propertyValue
    ) {
      return element;
    }
    if (typeof element === 'object') {
      const foundElement = findElementWithPropertyName(
        element,
        propertyName,
        propertyValue
      );
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return null;
};

/**
 * Prevent document body from scrolling
 * @param {Boolean} isDisplaying
 */
const preventBackgroundScroll = (isDisplaying) => {
  if (typeof document === 'undefined') return;

  const body = document.body;
  if (isDisplaying) {
    body.classList.add('overlay');
  } else {
    body.classList.remove('overlay');
  }
};

/**
 * Convert angle in degree unit to radians unit
 * @param {number} degree degree unit. From 0 to 360
 * @returns
 */
const convertDegToRad = (degree) => {
  return degree * (Math.PI / 180);
};

/**
 * Calculate X,Y coordinate on a XY axis plot from radius & angle.
 * @param {number} radius The radius of the circle.
 * @param {number} angle The angle of the point, in radians.
 * @returns
 * @throws Will throw an error if angle is not in radians.
 */
const calculateCoordinate = (radius, angle) => {
  const x = radius * Math.cos(angle);
  const y = radius * Math.sin(angle);

  return {x, y};
};

module.exports = {
  getFileHost,
  addFileNameSuffix,
  accessibleOnClick,
  groupObjByKeyToObj,
  findElementWithPropertyName,
  preventBackgroundScroll,
  convertDegToRad,
  calculateCoordinate,
};
