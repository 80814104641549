import React, {createRef, memo, useContext, useState} from 'react';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import ThemeContext from '../../store/theme-context';
import {BiChevronDown} from '@react-icons/all-files/bi/BiChevronDown';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import MenuItemDropdown from './MenuItemDropdown';
import {MenuDropdownTypes} from '../../_consts/menu-const';
import {LazyMotion, domAnimation, m} from 'framer-motion';

/**
 * Component have responsibility to render MenuItem
 * @param {Object} props - Component's props
 * @param {Object} props.menu - The Object of Menu Item
 * @param {boolean} props.isSticky - Detect when user scroll > 200px
 * @returns
 */

const MenuItem = ({menu, isSticky}) => {
  const [isOpen, setIsOpen] = useState(false);
  const themeContext = useContext(ThemeContext);
  const rootRef = createRef();

  let itemClassName = '';
  let iconClassName = '';
  let iconHoverClassName = '';
  let hoverChangeBackgroundClassName = '';
  let hoverItemClassName = '';

  const clickItemHandle = () => setIsOpen(false);

  if (themeContext.navTransparent) {
    if (isSticky) {
      itemClassName = 'text-black';
      hoverItemClassName = 'group-hover:text-primary';
      iconClassName = 'fill-black';
      iconHoverClassName = 'group-hover:fill-primary';
      hoverChangeBackgroundClassName = 'bg-primary';
    } else {
      itemClassName = 'text-white';
      hoverItemClassName = '';
      iconClassName = 'fill-white';
      iconHoverClassName = '';
      hoverChangeBackgroundClassName = 'bg-white';
    }
  } else {
    itemClassName = 'text-black';
    hoverItemClassName = 'group-hover:text-primary';
    iconClassName = 'fill-black';
    iconHoverClassName = 'group-hover:fill-primary';
    hoverChangeBackgroundClassName = 'bg-primary';
  }

  let hoverUnderlineEffectClassName = `relative no-underline before:absolute before:bottom-0 before:left-0 before:block before:h-[2px] before:w-full before:origin-top-left before:scale-x-0 before:${hoverChangeBackgroundClassName} before:transition-transform before:duration-150 before:ease-in before:content-[''] hover:before:scale-x-100`;

  return (
    <LazyMotion features={domAnimation} strict>
      <m.li
        ref={rootRef}
        className={`group relative flex h-full flex-row items-center ${hoverUnderlineEffectClassName}`}
        initial={'closed'}
        onHoverStart={() => {
          setIsOpen((prev) => true);
        }}
        onHoverEnd={() => {
          setIsOpen((prev) => false);
        }}
        animate={isOpen ? 'open' : 'closed'}
      >
        <UniversalLink
          to={menu.uri}
          activeClassName="!text-blue-700"
          className={`flex items-center px-3`}
          onClick={() => {}}
        >
          <p
            className={`mr-2 h-full text-sm font-bold ${itemClassName} capitalize ${TailwindTransitions.default[300]} ${hoverItemClassName}`}
          >
            {menu.label}
          </p>

          {/* Arrow Down icon */}
          {menu.childItems?.nodes?.length > 0 && (
            <BiChevronDown
              className={`${iconClassName} ${iconHoverClassName} h-4 w-4 ${TailwindTransitions.default[300]}`}
              fontWeight={800}
            />
          )}
        </UniversalLink>

        {menu.childItems?.nodes?.length > 0 &&
        menu.childItems?.nodes.length < 5 ? (
          <MenuItemDropdown
            menuItems={menu.childItems?.nodes}
            menuType={MenuDropdownTypes.list.col}
            onItemClick={clickItemHandle}
          />
        ) : (
          menu.childItems?.nodes?.length > 5 && (
            <MenuItemDropdown
              menuItems={menu.childItems?.nodes}
              menuType={MenuDropdownTypes.mega.col}
              onItemClick={clickItemHandle}
            />
          )
        )}
      </m.li>
    </LazyMotion>
  );
};

export default memo(MenuItem);
