import React, {memo} from 'react';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import LogoColor from '../../assets/logo-full-text.svg';
import {TbMapPin} from '@react-icons/all-files/tb/TbMapPin';
import {TbPhone} from '@react-icons/all-files/tb/TbPhone';
import {TbMail} from '@react-icons/all-files/tb/TbMail';
import {RiFacebookFill} from '@react-icons/all-files/ri/RiFacebookFill';
import {RiInstagramFill} from '@react-icons/all-files/ri/RiInstagramFill';
import {RiLinkedinFill} from '@react-icons/all-files/ri/RiLinkedinFill';
import {RiTwitterFill} from '@react-icons/all-files/ri/RiTwitterFill';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import BoxWrapper from '../Wrapper/BoxWrapper';

const Footer = () => {
  const url = process.env.WORDPRESS_ENDPOINT;

  return (
    <footer>
      <BoxWrapper
        className={`bg-gray-100" flex flex-col items-center px-4 md:px-5 lg:w-full lg:px-6`}
      >
        <div className="flex w-full flex-col justify-center gap-8 py-10 md:flex-row md:flex-wrap md:py-16 lg:max-w-7xl lg:gap-6">
          {/* 1 */}
          <div className="flex w-full flex-1 flex-col gap-6 md:w-fit lg:w-[30%]">
            <div className="">
              <img src={LogoColor} alt="Kdigi Logo" className="h-[60px]" />
            </div>

            <div className="">
              <h2 className="text-base font-bold">
                CÔNG TY TNHH ĐẦU TƯ CÔNG NGHỆ KDIGI
              </h2>
              <p className="mt-2 text-gray-500">GPKD số: 1801724493</p>
            </div>

            {/* Contact */}
            <div className="flex flex-col gap-4 text-gray-500">
              {/* Address */}
              <div className="flex flex-row gap-2 align-top">
                <TbMapPin className="mt-1 h-5 w-5 flex-none" />
                <p>
                  <UniversalLink
                    to={'https://goo.gl/maps/pD7nq47uQMGAJnKQ6'}
                    className={`w-fit text-gray-500 lg:bg-gradient-to-r lg:from-gray-500/30 lg:to-gray-500/30 lg:bg-[length:0%_40%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out group-hover:lg:bg-[length:100%_40%] group-hover:lg:bg-left-bottom hover:lg:bg-[length:100%_40%] hover:lg:bg-left-bottom`}
                  >
                    Số 69 đường 8C2, KDC Hưng Phú 1, Phường Hưng Phú, Quận Cái
                    Răng, Thành phố Cần Thơ
                  </UniversalLink>
                </p>
              </div>
              {/* Tel */}
              <div className="flex flex-row gap-2 align-top">
                <TbPhone className="mt-1 h-5 w-5 flex-none" />
                <p>
                  <UniversalLink
                    to={'tel:0868567868'}
                    className="w-fit text-gray-500 lg:bg-gradient-to-r lg:from-gray-500/30 lg:to-gray-500/30 lg:bg-[length:0%_40%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out group-hover:lg:bg-[length:100%_40%] group-hover:lg:bg-left-bottom hover:lg:bg-[length:100%_40%] hover:lg:bg-left-bottom"
                  >
                    0868.567.868
                  </UniversalLink>
                </p>
              </div>
              {/* Email */}
              <div className="flex flex-row gap-2 align-top">
                <TbMail className="mt-1 h-5 w-5 flex-none" />
                <p>
                  <UniversalLink
                    to={`mailto:lienhe@kdigi.vn`}
                    className="w-fit text-gray-500 lg:bg-gradient-to-r lg:from-gray-500/30 lg:to-gray-500/30 lg:bg-[length:0%_40%] lg:bg-right-bottom lg:bg-no-repeat lg:transition-[background-size] lg:duration-200 lg:ease-in-out group-hover:lg:bg-[length:100%_40%] group-hover:lg:bg-left-bottom hover:lg:bg-[length:100%_40%] hover:lg:bg-left-bottom"
                  >
                    lienhe@kdigi.vn
                  </UniversalLink>
                </p>
              </div>
            </div>

            {/* Social icons */}
            <div className="flex flex-row gap-4">
              {/* Facebook */}
              <UniversalLink to={''} className="">
                <RiFacebookFill
                  className={`h-6 w-6 fill-slate-500 hover:fill-blue-600 ${TailwindTransitions.default[200]}`}
                />
              </UniversalLink>
              {/* Twitter */}
              <UniversalLink to={''} className="">
                <RiTwitterFill
                  className={`h-6 w-6 fill-slate-500 hover:fill-blue-400 ${TailwindTransitions.default[200]}`}
                />
              </UniversalLink>
              {/* Instagram */}
              <UniversalLink to={''} className="">
                <RiInstagramFill
                  className={`h-6 w-6 fill-slate-500 hover:fill-red-500/90 ${TailwindTransitions.default[200]}`}
                />
              </UniversalLink>
              {/* Linkedin */}
              <UniversalLink to={''} className="">
                <RiLinkedinFill
                  className={`h-6 w-6 fill-slate-500 hover:fill-blue-500 ${TailwindTransitions.default[200]}`}
                />
              </UniversalLink>
            </div>
          </div>

          {/* 2 */}
          <div className="flex w-full flex-col gap-3 md:w-1/3 lg:w-[12%]">
            <h3 className="mb-1 font-bold">Về chúng tôi</h3>
            <p>
              <UniversalLink
                to={`${url}/tuyen-dung`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Tuyển dụng
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/bang-gia`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Bảng giá
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/lien-he`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Liên hệ
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/chinh-sach-bao-mat`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Chính sách bảo mật
              </UniversalLink>
            </p>
          </div>

          {/* 3 */}
          <div className="flex w-full flex-col gap-3 md:w-[49%] lg:w-[25%]">
            <h3 className="mb-1 font-bold">Thiết kế Website</h3>
            <p>
              <UniversalLink
                to={`${url}/`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Thiết kế Website theo yêu cầu
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-website-ban-hang-online`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Dịch vụ thiết kế Website bán hàng online chuyên nghiệp –
                Kdigi.vn
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-app-ban-hang`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Dịch vụ thiết kế App bán hàng – Thu lợi nhuận khủng
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-website-bat-dong-san`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Thiết kế Website bất động sản
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-web-can-tho`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Dịch vụ thiết kế web Cần Thơ uy tín
              </UniversalLink>
            </p>
          </div>

          {/* 4 */}
          <div className="flex w-full flex-col gap-3 md:w-[45%] lg:w-[25%]">
            <h3 className="mb-1 font-bold">Dịch vụ khác</h3>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-web-elearning`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Dịch vụ thiết kế Web Elearning chuyên nghiệp
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-website-khach-san`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Thiết kế Website khách sạn
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-website-nha-hang`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Thiết kế Website nhà hàng
              </UniversalLink>
            </p>
            <p>
              {' '}
              <UniversalLink
                to={`${url}/thiet-ke-web-nhanh`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Dịch vụ thiết kế Web nhanh chất lượng
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-website-rao-vat`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Dịch thiết kế Website rao vặt chuyên nghiệp
              </UniversalLink>
            </p>
            <p>
              <UniversalLink
                to={`${url}/thiet-ke-website-tham-my-vien-spa`}
                className={`w-fit text-gray-500 ${TailwindTransitions.textDecoration.underline.leftToRight.color_gray_500['40%']}`}
              >
                Dịch vụ thiết kế Website thẩm mỹ viện spa chuyên nghiệp – Kdigi
              </UniversalLink>
            </p>
          </div>
        </div>
        <div className="flex w-full flex-row justify-center py-4 text-sm text-gray-300">
          <div>
            <UniversalLink href={`/`} className={`hover:underline`}>
              Privacy Policy
            </UniversalLink>
            {` - KDIGI © ${new Date().getFullYear()} - All Rights Reserved.`}
          </div>
        </div>
      </BoxWrapper>
    </footer>
  );
};

export default memo(Footer);
