// import {lazy} from '@loadable/component';
import loadable from '@loadable/component';
import {globalHistory} from '@reach/router';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import LoadingBar from 'react-top-loading-bar';
import LoadingContext from '../../store/loading-context';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

// code splitting
const ScrollToTopButton = loadable(() =>
  import('../FloatingButtons/ScrollToTopButton')
);
const BottomToolBar = loadable(() => import('../BottomToolBar/BottomToolBar'));
const OverlaySearch = loadable(() => import('../Search/OverlaySearch'));
const OverlayMenu = loadable(() => import('../OverlayMenu/OverlayMenu'));

/**
 *
 * @param {Object} props - React Props
 * @param {Object} props.children - children element
 * @param {Object} props.location - Store location URL
 * @returns
 */
const Layout = ({children, location}) => {
  const isSSR = typeof window === 'undefined';

  const [isOpenOverlaySearch, setIsOpenOverlaySearch] = useState(false);

  /**
   * For Top Loading Bar Effect
   */
  const loadingContext = useContext(LoadingContext);

  useEffect(() => {
    // Detect page change completed event
    return globalHistory.listen(({action}) => {
      if (action === 'PUSH') {
        loadingContext.finishProgress();
      }
    });
  }, []);

  const styleBaseSettings = 'text-base-text bg-transparent';
  const styleTextSelection =
    'selection:bg-text-selection-background selection:text-text-selection';
  /**
   * Header handlers
   */
  const headerSearchClickHandler = useCallback(
    () => setIsOpenOverlaySearch(true),
    [setIsOpenOverlaySearch]
  );

  /**
   * Overlay search handlers
   */
  const overlaySearchCloseClickHandler = useCallback(
    () => setIsOpenOverlaySearch(false),
    [setIsOpenOverlaySearch]
  );

  const overlaySearchBgClickHandler = useCallback(
    () => setIsOpenOverlaySearch(false),
    [setIsOpenOverlaySearch]
  );

  const overlaySearchEscPressedHandler = useCallback(
    () => setIsOpenOverlaySearch(false),
    [setIsOpenOverlaySearch]
  );

  return (
    <div
      className={`${styleBaseSettings} ${styleTextSelection} overflow-x-clip`}
    >
      <LoadingBar
        color="#4667ec"
        height={'4px'}
        className={`${
          loadingContext.progress === 100
            ? 'transition-all duration-500 ease-in-out'
            : ''
        }`}
        progress={loadingContext.progress}
        onLoaderFinished={() => loadingContext.resetProgress()}
      />

      <Header
        headerHeight="h-[60px] lg:h-[70px]"
        onSearchClicked={headerSearchClickHandler}
      />
      <main className="min-h-[50vh]">{children}</main>
      <Footer />

      {/* Floating buttons */}
      {/* Only visible on Desktop */}
      <ScrollToTopButton
        className={`fixed bottom-6 right-0 z-10 mx-4 hidden h-fit lg:flex`}
      />

      {/* Only visible on Tablet & mobile */}
      <BottomToolBar className="fixed bottom-6 right-0 z-10" />

      {/* Overlays */}
      <OverlaySearch
        openOverlaySearch={isOpenOverlaySearch}
        onCloseClicked={overlaySearchCloseClickHandler}
        onBgClicked={overlaySearchBgClickHandler}
        onEscPressed={overlaySearchEscPressedHandler}
      />

      {/* Only visible on Tablet & mobile */}
      <div className="lg:hidden">
        <OverlayMenu />
      </div>
    </div>
  );
};

export default Layout;
