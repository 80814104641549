import React from 'react';

function IconDev({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 10.5 6 C 7.462 6 5 8.462 5 11.5 L 5 36.5 C 5 39.538 7.462 42 10.5 42 L 37.5 42 C 40.538 42 43 39.538 43 36.5 L 43 11.5 C 43 8.462 40.538 6 37.5 6 L 10.5 6 z M 8 16 L 40 16 L 40 36.5 C 40 37.881 38.881 39 37.5 39 L 10.5 39 C 9.119 39 8 37.881 8 36.5 L 8 16 z M 25.498047 18.978516 A 1.50015 1.50015 0 0 0 24.033203 20.185547 L 21.033203 34.185547 A 1.50015 1.50015 0 1 0 23.966797 34.814453 L 26.966797 20.814453 A 1.50015 1.50015 0 0 0 25.498047 18.978516 z M 30.484375 21.984375 A 1.50015 1.50015 0 0 0 29.439453 24.560547 L 32.378906 27.5 L 29.439453 30.439453 A 1.50015 1.50015 0 1 0 31.560547 32.560547 L 35.560547 28.560547 A 1.50015 1.50015 0 0 0 35.560547 26.439453 L 31.560547 22.439453 A 1.50015 1.50015 0 0 0 30.484375 21.984375 z M 17.470703 21.986328 A 1.50015 1.50015 0 0 0 16.439453 22.439453 L 12.439453 26.439453 A 1.50015 1.50015 0 0 0 12.439453 28.560547 L 16.439453 32.560547 A 1.50015 1.50015 0 1 0 18.560547 30.439453 L 15.621094 27.5 L 18.560547 24.560547 A 1.50015 1.50015 0 0 0 17.470703 21.986328 z" />
    </svg>
  );
}

export default IconDev;
