import {HiOutlineMenuAlt3} from '@react-icons/all-files/hi/HiOutlineMenuAlt3';
import React, {useContext} from 'react';
import {accessibleOnClick} from '../../utils/helperFunctions';
import OverlayMenuContext from '../../store/overlay-menu-context';

/**
 * @param {Object} props - Component's props
 * @param {string} props.className - className of Mobile Menu Button
 * @returns
 */
const HeaderMobileMenuButton = ({className}) => {
  // For Handler Open OverLay Menu State
  const overlayMenuContext = useContext(OverlayMenuContext);

  return (
    <div
      className={`${className} lg:hidden`}
      {...accessibleOnClick(
        () => overlayMenuContext.setOpenOverlayMenuState(true),
        null,
        'Open Menu'
      )}
    >
      <HiOutlineMenuAlt3 size={24} />
    </div>
  );
};

export default HeaderMobileMenuButton;
