module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KDIGI","short_name":"KDIGI","description":"Cung cấp giải pháp chuyển đổi số cho doanh nghiệp","lang":"en","icon":"src/assets/favicon.png","cache_busting_mode":"none","background_color":"#fafafa","theme_color":"#3C4ADE","display":"standalone","start_url":"/","localize":[{"start_url":"/","lang":"vi","name":"KDIGI","short_name":"KDIGI","description":"Cung cấp giải pháp chuyển đổi số cho doanh nghiệp"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.kdigi.vn/graphql","schema":{"perPage":20,"requestConcurrency":50,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2Qe3wk7u3KHirKtRPSNj5Zx0QFb","devKey":"2Qe3wk7u3KHirKtRPSNj5Zx0QFb","trackPage":true,"delayLoad":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
