const {ConstSearchTypes} = require('../_consts/search-consts');

/**
 * Store app configs
 */
const AppConfig = {
  // HtmlLang
  htmlLang: 'en',

  // Sticky height (px)
  navStickyHeight: 10,

  // Scroll height to display Scroll To Top button
  scrollToTopHeight: 80,

  // Pagination
  pageDefaultPostPerPage: 10,

  categoryUncategorizedName: 'chưa phân loại', // Wordpress Uncategorized category name

  // Permalink
  permalinkPageName: 'trang', // to set pagination: a/{permalinkPageName}/2, a/{permalinkPageName}/3, etc.

  // Post Latest & Related
  postLatestLength: 4, // from 1 to 10
  postRelatedLength: 6, // from 1 to 10
  postRelatedMaxNumTagPosts: 3, // Max number of related tag in related posts, from 0 to postRelatedLength.

  // Post
  postTOCQueryTag: 'h2,h3,h4,h5,h6', //heading tag to include in table of content
  postDateFormat: 'DD/MM/YYYY',

  // Post Widget
  postWidgetTocDisplayTag: 'h2,h3', // heading tag to be included in Table of Content widget

  // Pagination
  paginationDisplayMax: 5, // Number of page button to be displayed. Eg: max is 3 => display `[prev] [1] [2] [3] [next]`. The number [1,...] but recommend 3 or more.

  // Link
  linkUseOutboundLink: false, //turn on if want to track outbound link using Google Analytics (require Google Analytics package)

  // Image
  imageSuffix: '-150x150',

  // Meilisearch
  MeilisearchMaxResult: 15,
  MeilisearchDebounceTime: 300,
  MeilisearchExcerptLength: 100,

  // Search

  // Sort order.
  // If this key is set, you must include all types in search or the search result will be incorrect
  searchTypePriority: {
    1: ConstSearchTypes.post,
    2: ConstSearchTypes.page,
    3: ConstSearchTypes.category,
    4: ConstSearchTypes.tag,
    5: ConstSearchTypes.author,
  },
  // Result limit.
  // If this key is set, you must include all types in search or the search result will be incorrect
  searchTypeLimit: {
    [ConstSearchTypes.post]: 5,
    [ConstSearchTypes.page]: 5,
    [ConstSearchTypes.category]: 5,
    [ConstSearchTypes.tag]: 5,
    [ConstSearchTypes.author]: 5,
  },

  // Fb comment
  fbCommentLanguage: 'en_US',

  // Axios
  axiosTimeout: 10000,
};

module.exports = AppConfig;
