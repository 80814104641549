import React, { createContext, useState } from "react";

const ThemeContext = createContext({
    navTransparent: false,
    setNavTransparent: () => {},
})

export default ThemeContext;


export const ThemeContextProvider = ({children}) => {
    const [navTransparentState, setNavTransparentState] = useState(false);

    const contextValue ={
        navTransparent: navTransparentState,
        setNavTransparent: setNavTransparentState,
    }

    return (
        <ThemeContext.Provider value={contextValue}>
            {children}
        </ThemeContext.Provider>
    )
}
