import React from 'react';

const IconLogo = ({className}) => {
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 295 75.29"
      className={`${className}`}
    >
      <path
        d="M99,29.26h9.32V55.94l25.24-26.68H145L123,52.07l23,30.24H134.73L116.62,58.44l-8.26,8.49V82.32H99V29.26Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M154.22,29.26H174c16.67,0,28.19,11.44,28.19,26.37v.15c0,14.93-11.52,26.53-28.19,26.53H154.22ZM174,73.83c11.14,0,18.42-7.51,18.42-17.89v-.15c0-10.38-7.28-18-18.42-18H163.54V73.83Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M214.26,29.26h9.32V82.31h-9.32v-53Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M235.64,55.93v-.15c0-14.85,11.29-27.44,27.36-27.44,9.17,0,14.93,2.58,20.39,7.2l-5.91,7.05c-4.09-3.49-8.11-5.68-14.86-5.68-9.7,0-17.2,8.64-17.2,18.72v.15c0,10.91,7.2,19,18,19a21.46,21.46,0,0,0,12.81-4V60.78H262.7V52.67h22.59V75a34,34,0,0,1-22.13,8.26c-16.6,0-27.51-11.82-27.51-27.28Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M298.18,29.26h9.32V82.31h-9.32Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M40.63,29.59c.54-.54.36-1-.41-1H13.89A1.39,1.39,0,0,0,12.5,30V56.33c0,.76.44,1,1,.41Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M56.15,57.71s.46-.44,1-1L84.3,29.58c.54-.54.36-1-.41-1H57.53a3.87,3.87,0,0,0-2.37,1l-18,18-2,2L13.48,71.29a3.87,3.87,0,0,0-1,2.37V100c0,.76.44,1,1,.41L40.63,73.26c.54-.54,1-1,1-1s.44-.46,1-1L55.15,58.7c.54-.54,1-1,1-1Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M57.15,73.25a1.39,1.39,0,0,0-2,0L28,100.4c-.54.54-.36,1,.41,1H83.89c.76,0,1-.44.41-1Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M103,90.18h1.15l5,10.95H107.8l-1.27-2.87h-5.92l-1.29,2.87H98.06l5-10.95Zm3,7-2.47-5.55-2.49,5.55Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M110.39,100.11l.61-.86a4.58,4.58,0,0,0,2.78,1c1,0,1.66-.48,1.66-1.27v0c0-.79-1-1.12-2-1.42-1.24-.38-2.66-.78-2.66-2.27v0c0-1.39,1.15-2.3,2.73-2.3a5.48,5.48,0,0,1,2.89.88l-.54.9a4.49,4.49,0,0,0-2.38-.78c-1,0-1.55.5-1.55,1.16v0c0,.76,1,1.06,2.07,1.39,1.24.37,2.58.85,2.58,2.3v0c0,1.52-1.26,2.41-2.86,2.41a5.51,5.51,0,0,1-3.32-1.18Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M118.8,93.1H120v1.62a3.65,3.65,0,0,1,3.08-1.79,3.92,3.92,0,0,1,3.84,4.16v0a4,4,0,0,1-3.84,4.18A3.59,3.59,0,0,1,120,99.59v4h-1.2V93.09Zm6.88,4v0A2.88,2.88,0,0,0,122.85,94a3,3,0,0,0-2.91,3.09v0a3,3,0,0,0,2.91,3.11,2.87,2.87,0,0,0,2.83-3.09Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M129.14,90h1.37v1.32h-1.37Zm.08,3.08h1.2v8h-1.2Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M133.19,93.1h1.2v2.1A3.46,3.46,0,0,1,137.67,93v1.29h-.09c-1.76,0-3.19,1.25-3.19,3.68v3.2h-1.2v-8Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M140.2,97.61a2.8,2.8,0,0,0,2.83,2.67,3.33,3.33,0,0,0,2.53-1.13l.74.67a4.07,4.07,0,0,1-3.31,1.5,4,4,0,0,1-4-4.19,4,4,0,0,1,3.85-4.19c2.38,0,3.74,1.89,3.74,4.25a3.57,3.57,0,0,1,0,.42H140.2Zm5.17-1a2.64,2.64,0,0,0-2.56-2.7,2.78,2.78,0,0,0-2.61,2.7Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M153.76,99V94.16h-1.12V93.1h1.12V90.68H155V93.1h2.55v1.06H155v4.69a1.18,1.18,0,0,0,1.35,1.34,2.48,2.48,0,0,0,1.16-.28v1a2.88,2.88,0,0,1-1.45.35A2,2,0,0,1,153.75,99Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M159.11,97.16v0a4.19,4.19,0,0,1,8.37,0v0a4.16,4.16,0,0,1-4.21,4.19,4.11,4.11,0,0,1-4.17-4.16Zm7.15,0v0a3,3,0,0,0-3-3.14,3,3,0,0,0-2.94,3.11v0a3,3,0,1,0,5.92,0Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M174.51,90.26h3.77c3.42,0,5.78,2.35,5.78,5.41v0a5.44,5.44,0,0,1-5.78,5.43h-3.77Zm3.77,9.74a4.2,4.2,0,0,0,4.51-4.27v0a4.24,4.24,0,0,0-4.51-4.31h-2.55V100h2.55Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M186.48,90h1.37v1.32h-1.37Zm.08,3.08h1.2v8h-1.2Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M190.44,102.53l.54-.93a5.13,5.13,0,0,0,3.09,1A2.63,2.63,0,0,0,197,99.77v-.94a3.81,3.81,0,0,1-3.15,1.69,3.73,3.73,0,0,1-3.78-3.76v0a3.73,3.73,0,0,1,3.78-3.79A3.83,3.83,0,0,1,197,94.54V93.09h1.19v6.63a3.86,3.86,0,0,1-1,2.84,4.16,4.16,0,0,1-3.06,1.07,6.44,6.44,0,0,1-3.65-1.12ZM197,96.74v0A2.81,2.81,0,0,0,194.08,94a2.65,2.65,0,0,0-2.78,2.7v0a2.71,2.71,0,0,0,2.78,2.73A2.85,2.85,0,0,0,197,96.73Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M200.88,90h1.37v1.32h-1.37Zm.08,3.08h1.2v8H201Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M205.35,99V94.16h-1.12V93.1h1.12V90.68h1.2V93.1h2.55v1.06h-2.55v4.69a1.18,1.18,0,0,0,1.35,1.34,2.45,2.45,0,0,0,1.16-.28v1a2.87,2.87,0,0,1-1.44.35A2,2,0,0,1,205.35,99Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M210.6,98.81v0c0-1.73,1.4-2.63,3.43-2.63a8.53,8.53,0,0,1,2.46.34v-.24c0-1.43-.87-2.17-2.38-2.17a5.35,5.35,0,0,0-2.44.57l-.36-1a6.36,6.36,0,0,1,2.92-.69,3.44,3.44,0,0,1,2.59.89,3.2,3.2,0,0,1,.84,2.36v4.91h-1.18V100a3.49,3.49,0,0,1-2.89,1.37c-1.51,0-3-.84-3-2.5Zm5.9-.62v-.75a8.32,8.32,0,0,0-2.35-.34c-1.49,0-2.35.64-2.35,1.61v0c0,1,.95,1.6,2,1.6,1.47,0,2.67-.87,2.67-2.16Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M220.31,89.79h1.2v11.34h-1.2Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M229.26,90.26h1.23v10.87h-1.23Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M233.46,93.1h1.2v1.4a3.05,3.05,0,0,1,2.75-1.56,2.94,2.94,0,0,1,3.08,3.21v5H239.3V96.45A2.13,2.13,0,0,0,237.08,94a2.34,2.34,0,0,0-2.41,2.51v4.6h-1.2v-8Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M243,93.1h1.2v1.4A3.05,3.05,0,0,1,247,92.94a2.94,2.94,0,0,1,3.08,3.21v5h-1.19V96.45A2.13,2.13,0,0,0,246.65,94a2.34,2.34,0,0,0-2.41,2.51v4.6H243v-8Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M252.1,97.16v0a4.19,4.19,0,0,1,8.37,0v0a4.16,4.16,0,0,1-4.21,4.19A4.1,4.1,0,0,1,252.1,97.16Zm7.14,0v0a3,3,0,0,0-3-3.14,3,3,0,0,0-2.93,3.11v0a3,3,0,1,0,5.92,0Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M261.37,93.1h1.32l2.71,6.66,2.72-6.66h1.29l-3.5,8.1h-1.05l-3.48-8.1Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M270.29,98.81v0c0-1.73,1.4-2.63,3.43-2.63a8.53,8.53,0,0,1,2.46.34v-.24c0-1.43-.87-2.17-2.38-2.17a5.35,5.35,0,0,0-2.44.57l-.36-1a6.36,6.36,0,0,1,2.92-.69,3.44,3.44,0,0,1,2.59.89,3.2,3.2,0,0,1,.84,2.36v4.91h-1.18V100a3.49,3.49,0,0,1-2.89,1.37c-1.51,0-3-.84-3-2.5Zm5.9-.62v-.75a8.32,8.32,0,0,0-2.35-.34c-1.49,0-2.35.64-2.35,1.61v0c0,1,.95,1.6,2,1.6,1.47,0,2.67-.87,2.67-2.16Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M280.23,99V94.16h-1.12V93.1h1.12V90.68h1.2V93.1H284v1.06h-2.55v4.69a1.18,1.18,0,0,0,1.35,1.34,2.45,2.45,0,0,0,1.16-.28v1a2.87,2.87,0,0,1-1.44.35A2,2,0,0,1,280.23,99Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M286.12,90h1.37v1.32h-1.37Zm.08,3.08h1.2v8h-1.2Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M289.67,97.16v0a4.19,4.19,0,0,1,8.37,0v0a4.16,4.16,0,0,1-4.21,4.19A4.1,4.1,0,0,1,289.67,97.16Zm7.14,0v0a3,3,0,0,0-3-3.14,3,3,0,0,0-2.93,3.11v0a3,3,0,1,0,5.92,0Z"
        transform="translate(-12.5 -28.34)"
      />
      <path
        d="M300.2,93.1h1.2v1.4a3.05,3.05,0,0,1,2.75-1.56,2.94,2.94,0,0,1,3.08,3.21v5H306V96.45A2.13,2.13,0,0,0,303.82,94a2.34,2.34,0,0,0-2.41,2.51v4.6h-1.2v-8Z"
        transform="translate(-12.5 -28.34)"
      />
    </svg>
  );
};

export default IconLogo;
