import React from 'react';

function IconCRM({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 9.5 5 C 7.5850452 5 6 6.5850452 6 8.5 L 6 11.210938 L 4.1074219 15.943359 A 1.50015 1.50015 0 0 0 4.1972656 17.242188 A 1.50015 1.50015 0 0 0 4.1972656 17.244141 C 4.4255533 18.839081 5.0641162 20.30273 6 21.521484 L 6 42.5 A 1.50015 1.50015 0 0 0 7.5 44 L 40.5 44 A 1.50015 1.50015 0 0 0 42 42.5 L 42 21.525391 C 42.93955 20.303208 43.579477 18.832965 43.806641 17.232422 A 1.50015 1.50015 0 0 0 43.892578 15.943359 L 42 11.210938 L 42 8.5 C 42 6.5850452 40.414955 5 38.5 5 L 9.5 5 z M 9.5 8 L 38.5 8 C 38.795045 8 39 8.2049548 39 8.5 L 39 11.5 A 1.50015 1.50015 0 0 0 39.107422 12.056641 L 40.925781 16.603516 C 40.61678 19.635474 38.120928 22 35 22 C 33.332692 22 31.840802 21.329533 30.753906 20.240234 A 1.50015 1.50015 0 0 0 28.6875 20.185547 C 27.440099 21.313006 25.810875 22 24 22 C 22.189125 22 20.559901 21.313006 19.3125 20.185547 A 1.50015 1.50015 0 0 0 17.246094 20.240234 C 16.159198 21.32953 14.667308 22 13 22 C 9.8778428 22 7.3823584 19.63533 7.0742188 16.603516 L 8.8925781 12.056641 A 1.50015 1.50015 0 0 0 9 11.5 L 9 8.5 C 9 8.2049548 9.2049548 8 9.5 8 z M 29.5 10 A 1.5 1.5 0 0 0 29.5 13 A 1.5 1.5 0 0 0 29.5 10 z M 34.5 10 A 1.5 1.5 0 0 0 34.5 13 A 1.5 1.5 0 0 0 34.5 10 z M 18.335938 23.054688 C 19.960763 24.20091 21.862238 25 24 25 C 26.137762 25 28.039237 24.200909 29.664062 23.054688 C 31.174664 24.19798 32.967968 25 35 25 C 36.445243 25 37.801322 24.648914 39 24.033203 L 39 41 L 35 41 L 35 29.5 A 1.50015 1.50015 0 0 0 33.5 28 L 22.5 28 A 1.50015 1.50015 0 0 0 21 29.5 L 21 41 L 9 41 L 9 24.033203 C 10.19861 24.649179 11.554288 25 13 25 C 15.032032 25 16.825336 24.19798 18.335938 23.054688 z M 24 31 L 32 31 L 32 41 L 24 41 L 24 31 z" />
    </svg>
  );
}

export default IconCRM;
