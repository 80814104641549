import React from 'react';

function IconCloud({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 24 8 C 16.522892 8 10.415747 13.720449 9.6523438 21 L 9 21 C 4.0472805 21 0 25.047281 0 30 C 0 34.952719 4.0472805 39 9 39 L 24 39 L 39 39 C 43.952719 39 48 34.952719 48 30 C 48 25.047281 43.952719 21 39 21 L 38.347656 21 C 37.584253 13.720449 31.477108 8 24 8 z M 24 11 C 30.369415 11 35.5 16.130585 35.5 22.5 A 1.50015 1.50015 0 0 0 37 24 L 39 24 C 42.331281 24 45 26.668719 45 30 C 45 33.331281 42.331281 36 39 36 L 24 36 L 9 36 C 5.6687195 36 3 33.331281 3 30 C 3 26.668719 5.6687195 24 9 24 L 11 24 A 1.50015 1.50015 0 0 0 12.5 22.5 C 12.5 16.130585 17.630585 11 24 11 z" />
    </svg>
  );
}

export default IconCloud;
