import React from 'react';
import {createContext, useState} from 'react';

const OverlayMenuContext = createContext({
  openOverlayMenuState: false,
  setOpenOverlayMenuState: () => {},
});

export default OverlayMenuContext;

export const OverlayMenuProvider = ({children}) => {
  const [isOpenOverlayMenu, setIsOpenOverlayMenu] = useState(false);

  const contextValue = {
    openOverlayMenuState: isOpenOverlayMenu,
    setOpenOverlayMenuState: setIsOpenOverlayMenu,
  };

  return (
    <OverlayMenuContext.Provider value={contextValue}>
      {children}
    </OverlayMenuContext.Provider>
  );
};
