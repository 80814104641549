import React, {forwardRef, useContext} from 'react';
import {Link} from 'gatsby';
import AppConfig from '../../../config/appConfig';
import LoadingContext from '../../../store/loading-context';

/**
 * Determine which component will be use for setting link: Gatsby's
 * <Link> for internal links, Google Analytics's <OutboundLink> for
 * external links or normal html <a> tag for external links.
 * @param {Object} props - Component's props
 * @param {boolean} props.useATag - force using of <a> tag
 * @param {boolean} props.escapeEmptyLink - If true, this component will create a `span` element instead of `a` & `Link` when `to = ''` or `to = '#'`
 * @param {string} props.to - destination URL
 * @param {string} props.className - set Gatsby's <Link> class name
 * @param {string} props.activeClassName - set Gatsby's <Link> active class name
 * @param {string} props.partiallyActive - set Gatsby's <Link> partially class name
 * @param {any} props.other custom props
 * @returns
 */
const UniversalLink = forwardRef(
  (
    {
      children,
      useATag = false,
      escapeEmptyLink = false,
      target = '_blank',
      to = '/',
      className = '',
      activeClassName = '',
      partiallyActive = false,
      onClick = (e) => {},
      ...other
    },
    ref
  ) => {
    // Set progress for loading bar
    const loadingContext = useContext(LoadingContext);

    // use <a> tag
    if (useATag) {
      return (
        <a
          ref={ref}
          href={to}
          target={target}
          className={`${className}`}
          onClick={onClick}
          {...other}
        >
          {children}
        </a>
      );
    }

    if (escapeEmptyLink && (to === '' || to === '#')) {
      return (
        <span
          className={`cursor-pointer select-none ${className}`}
          onClick={onClick}
          {...other}
        >
          {children}
        </span>
      );
    }

    const internal = /^\/(?!\/)/.test(to);

    // Use Gatsby Link for internal links
    if (internal) {
      return (
        <Link
          ref={ref}
          to={to}
          className={`${className}`}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          onClick={(e) => {
            loadingContext.startProgress();
            onClick(e);
          }}
          {...other}
        >
          {children}
        </Link>
      );
    }

    // Use Google Analytics's OutboundLink or <a> tag for external links
    return AppConfig.linkUseOutboundLink ? (
      // <OutboundLink href={to} {...other}>
      //   {children}
      // </OutboundLink>
      <></>
    ) : (
      <a
        ref={ref}
        href={to}
        target={target}
        className={`${className}`}
        onClick={onClick}
        {...other}
      >
        {children}
      </a>
    );
  }
);

export default UniversalLink;
