import { useStaticQuery, graphql } from 'gatsby';

export const useMenuQuery = () => {
  const data = useStaticQuery(graphql`
    fragment menuChild on WpMenuItem {
      id
      databaseId
      label
      uri
      parentId
    }

    fragment recursiveChildMenu on WpMenuItem {
      ...menuChild
      childItems {
        nodes {
          ...menuChild
          childItems {
            nodes {
              ...menuChild
            }
          }
        }
      }
    }
    query {
      site {
        siteMetadata {
          title
        }
      }
      menu: wpMenu(locations: { eq: MENU_1 }) {
        menuItems {
          nodes {
            ...menuChild
            childItems {
              nodes {
                ...recursiveChildMenu
              }
            }
          }
        }
      }
    }
  `);
  return data;
};
