import React from 'react';

function IconProgramming({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 8.5 5 C 6.0324991 5 4 7.0324991 4 9.5 L 4 30.5 C 4 32.967501 6.0324991 35 8.5 35 L 17 35 L 17 40 L 13.5 40 A 1.50015 1.50015 0 1 0 13.5 43 L 18.253906 43 A 1.50015 1.50015 0 0 0 18.740234 43 L 29.253906 43 A 1.50015 1.50015 0 0 0 29.740234 43 L 34.5 43 A 1.50015 1.50015 0 1 0 34.5 40 L 31 40 L 31 35 L 39.5 35 C 41.967501 35 44 32.967501 44 30.5 L 44 9.5 C 44 7.0324991 41.967501 5 39.5 5 L 8.5 5 z M 8.5 8 L 39.5 8 C 40.346499 8 41 8.6535009 41 9.5 L 41 30.5 C 41 31.346499 40.346499 32 39.5 32 L 29.746094 32 A 1.50015 1.50015 0 0 0 29.259766 32 L 18.746094 32 A 1.50015 1.50015 0 0 0 18.259766 32 L 8.5 32 C 7.6535009 32 7 31.346499 7 30.5 L 7 9.5 C 7 8.6535009 7.6535009 8 8.5 8 z M 25.521484 11.978516 A 1.50015 1.50015 0 0 0 24.039062 13.162109 L 21.039062 26.162109 A 1.50015 1.50015 0 1 0 23.960938 26.837891 L 26.960938 13.837891 A 1.50015 1.50015 0 0 0 25.521484 11.978516 z M 17.503906 14.984375 A 1.50015 1.50015 0 0 0 16.361328 15.523438 L 13.361328 19.023438 A 1.50015 1.50015 0 0 0 13.361328 20.976562 L 16.361328 24.476562 A 1.50015 1.50015 0 1 0 18.638672 22.523438 L 16.476562 20 L 18.638672 17.476562 A 1.50015 1.50015 0 0 0 17.503906 14.984375 z M 30.451172 14.984375 A 1.50015 1.50015 0 0 0 29.361328 17.476562 L 31.523438 20 L 29.361328 22.523438 A 1.50015 1.50015 0 1 0 31.638672 24.476562 L 34.638672 20.976562 A 1.50015 1.50015 0 0 0 34.638672 19.023438 L 31.638672 15.523438 A 1.50015 1.50015 0 0 0 30.451172 14.984375 z M 20 35 L 28 35 L 28 40 L 20 40 L 20 35 z" />
    </svg>
  );
}

export default IconProgramming;
