exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dich-vu-block-chain-js": () => import("./../../../src/pages/dich-vu/block-chain.js" /* webpackChunkName: "component---src-pages-dich-vu-block-chain-js" */),
  "component---src-pages-dich-vu-devops-js": () => import("./../../../src/pages/dich-vu/devops.js" /* webpackChunkName: "component---src-pages-dich-vu-devops-js" */),
  "component---src-pages-dich-vu-dich-vu-cloud-js": () => import("./../../../src/pages/dich-vu/dich-vu-cloud.js" /* webpackChunkName: "component---src-pages-dich-vu-dich-vu-cloud-js" */),
  "component---src-pages-dich-vu-dich-vu-hosting-js": () => import("./../../../src/pages/dich-vu/dich-vu-hosting.js" /* webpackChunkName: "component---src-pages-dich-vu-dich-vu-hosting-js" */),
  "component---src-pages-dich-vu-dich-vu-seo-js": () => import("./../../../src/pages/dich-vu/dich-vu-seo.js" /* webpackChunkName: "component---src-pages-dich-vu-dich-vu-seo-js" */),
  "component---src-pages-dich-vu-dich-vu-ten-mien-js": () => import("./../../../src/pages/dich-vu/dich-vu-ten-mien.js" /* webpackChunkName: "component---src-pages-dich-vu-dich-vu-ten-mien-js" */),
  "component---src-pages-dich-vu-giai-phap-crm-js": () => import("./../../../src/pages/dich-vu/giai-phap-crm.js" /* webpackChunkName: "component---src-pages-dich-vu-giai-phap-crm-js" */),
  "component---src-pages-dich-vu-giai-phap-digital-workplace-js": () => import("./../../../src/pages/dich-vu/giai-phap-digital-workplace.js" /* webpackChunkName: "component---src-pages-dich-vu-giai-phap-digital-workplace-js" */),
  "component---src-pages-dich-vu-giai-phap-erp-js": () => import("./../../../src/pages/dich-vu/giai-phap-erp.js" /* webpackChunkName: "component---src-pages-dich-vu-giai-phap-erp-js" */),
  "component---src-pages-dich-vu-giai-phap-google-js": () => import("./../../../src/pages/dich-vu/giai-phap-google.js" /* webpackChunkName: "component---src-pages-dich-vu-giai-phap-google-js" */),
  "component---src-pages-dich-vu-giai-phap-microsoft-js": () => import("./../../../src/pages/dich-vu/giai-phap-microsoft.js" /* webpackChunkName: "component---src-pages-dich-vu-giai-phap-microsoft-js" */),
  "component---src-pages-dich-vu-index-js": () => import("./../../../src/pages/dich-vu/index.js" /* webpackChunkName: "component---src-pages-dich-vu-index-js" */),
  "component---src-pages-dich-vu-lap-trinh-ung-dung-di-dong-js": () => import("./../../../src/pages/dich-vu/lap-trinh-ung-dung-di-dong.js" /* webpackChunkName: "component---src-pages-dich-vu-lap-trinh-ung-dung-di-dong-js" */),
  "component---src-pages-dich-vu-lap-trinh-web-app-js": () => import("./../../../src/pages/dich-vu/lap-trinh-web-app.js" /* webpackChunkName: "component---src-pages-dich-vu-lap-trinh-web-app-js" */),
  "component---src-pages-dich-vu-phan-mem-theo-yeu-cau-js": () => import("./../../../src/pages/dich-vu/phan-mem-theo-yeu-cau.js" /* webpackChunkName: "component---src-pages-dich-vu-phan-mem-theo-yeu-cau-js" */),
  "component---src-pages-dich-vu-thiet-ke-website-js": () => import("./../../../src/pages/dich-vu/thiet-ke-website.js" /* webpackChunkName: "component---src-pages-dich-vu-thiet-ke-website-js" */),
  "component---src-pages-gioi-thieu-js": () => import("./../../../src/pages/gioi-thieu.js" /* webpackChunkName: "component---src-pages-gioi-thieu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lien-he-js": () => import("./../../../src/pages/lien-he.js" /* webpackChunkName: "component---src-pages-lien-he-js" */),
  "component---src-pages-tuyen-dung-js": () => import("./../../../src/pages/tuyen-dung.js" /* webpackChunkName: "component---src-pages-tuyen-dung-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

