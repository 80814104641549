import React, {createContext, useEffect, useState} from 'react';
const LoadingContext = createContext({
  progress: 0,
  /**
   * Start commutative progress, `1` point every 100ms.
   *
   * The value start at `10` and finish at `90`. To reach `100`, you need to call function `finishProgress`
   */
  startProgress: () => {},
  /**
   * Set progress value to `100`
   */
  finishProgress: () => {},
  /**
   * Reset progress to `0`
   */
  resetProgress: () => {},
});

export default LoadingContext;

export const LoadingContextProvider = ({children}) => {
  const [progress, setProgress] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let interval, timeout;
    if (isRunning) {
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 90) {
            return prev + 1;
          }
        });
      }, 100);

      // Set timeout after 60s
      timeout = setTimeout(() => {
        setProgress(100);
      }, 60000);
    }

    return () => {
      interval && clearInterval(interval);
      timeout && clearTimeout(timeout);
    };
  }, [isRunning]);

  /**
   * Start commutative progress, `1` point every 100ms.
   *
   * The value start at `10` and finish at `90`. To reach `100`, you need to call function `finishProgress`
   */
  const startProgress = () => {
    setIsRunning(true);
  };

  /**
   * Set progress value to `100`
   */
  const finishProgress = () => {
    setProgress(100);
  };

  /**
   * Reset progress to `0`
   */
  const resetProgress = () => {
    setProgress(0);
    setIsRunning(false);
  };

  const contextValue = {
    progress,
    startProgress,
    finishProgress,
    resetProgress,
  };

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};
