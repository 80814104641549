import React from 'react';

function IconMobileDev({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 16.5 4 C 14.019 4 12 6.019 12 8.5 L 12 15.550781 L 15 12.550781 L 15 8.5 C 15 7.673 15.673 7 16.5 7 L 31.5 7 C 32.327 7 33 7.673 33 8.5 L 33 12.550781 L 36 15.550781 L 36 8.5 C 36 6.019 33.981 4 31.5 4 L 16.5 4 z M 24 10 A 1.5 1.5 0 0 0 24 13 A 1.5 1.5 0 0 0 24 10 z M 31.484375 14.484375 A 1.50015 1.50015 0 0 0 30.439453 17.060547 L 37.378906 24 L 30.439453 30.939453 A 1.50015 1.50015 0 1 0 32.560547 33.060547 L 40.560547 25.060547 A 1.50015 1.50015 0 0 0 40.560547 22.939453 L 32.560547 14.939453 A 1.50015 1.50015 0 0 0 31.484375 14.484375 z M 16.470703 14.486328 A 1.50015 1.50015 0 0 0 15.439453 14.939453 L 7.4394531 22.939453 A 1.50015 1.50015 0 0 0 7.4394531 25.060547 L 15.439453 33.060547 A 1.50015 1.50015 0 1 0 17.560547 30.939453 L 10.621094 24 L 17.560547 17.060547 A 1.50015 1.50015 0 0 0 16.470703 14.486328 z M 12 32.449219 L 12 39.5 C 12 41.981 14.019 44 16.5 44 L 31.5 44 C 33.981 44 36 41.981 36 39.5 L 36 32.449219 L 33 35.449219 L 33 39.5 C 33 40.327 32.327 41 31.5 41 L 16.5 41 C 15.673 41 15 40.327 15 39.5 L 15 35.449219 L 12 32.449219 z M 21.5 35 A 1.50015 1.50015 0 1 0 21.5 38 L 26.5 38 A 1.50015 1.50015 0 1 0 26.5 35 L 21.5 35 z" />
    </svg>
  );
}

export default IconMobileDev;
