import React from 'react';

function IconDevOps({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M 24 5 C 16.51 5 10.330078 10.71 9.5800781 18 L 9 18 C 4.04 18 0 22.04 0 27 C 0 31.96 4.04 36 9 36 L 10.939453 36 C 11.369453 35.02 11.729297 34.02 12.029297 33 L 9 33 C 5.69 33 3 30.31 3 27 C 3 23.69 5.69 21 9 21 L 11 21 C 11.83 21 12.5 20.33 12.5 19.5 C 12.5 13.16 17.66 8 24 8 C 30.34 8 35.5 13.16 35.5 19.5 C 35.5 20.33 36.17 21 37 21 L 39 21 C 42.31 21 45 23.69 45 27 C 45 30.31 42.31 33 39 33 L 35.970703 33 C 36.270703 34.02 36.630547 35.02 37.060547 36 L 39 36 C 43.96 36 48 31.96 48 27 C 48 22.04 43.96 18 39 18 L 38.419922 18 C 37.669922 10.71 31.49 5 24 5 z M 17.476562 21.978516 A 1.50015 1.50015 0 0 0 16 23.5 L 16 26.117188 C 16 29.870897 15.225643 33.573621 13.759766 37.011719 A 3 3 0 0 0 14 43 A 3 3 0 0 0 16.470703 38.302734 C 18.127133 34.461512 19 30.31818 19 26.117188 L 19 23.5 A 1.50015 1.50015 0 0 0 17.476562 21.978516 z M 23.976562 21.978516 A 1.50015 1.50015 0 0 0 22.5 23.5 L 22.5 37.40625 A 3 3 0 0 0 24 43 A 3 3 0 0 0 25.5 37.404297 L 25.5 23.5 A 1.50015 1.50015 0 0 0 23.976562 21.978516 z M 30.476562 21.978516 A 1.50015 1.50015 0 0 0 29 23.5 L 29 26.117188 C 29 30.318435 29.874623 34.461317 31.53125 38.302734 A 3 3 0 0 0 34 43 A 3 3 0 0 0 34.240234 37.011719 C 32.774357 33.573621 32 29.870897 32 26.117188 L 32 23.5 A 1.50015 1.50015 0 0 0 30.476562 21.978516 z" />
    </svg>
  );
}

export default IconDevOps;
