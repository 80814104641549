import React from 'react';

/**
 * Box Wrapper
 * @param {Object} props
 * @param {string} props.paddingClassName Set padding class name for the Wrapper.
 *
 * Default: `px-4 md:px-5 lg:px-6`
 * @returns
 */
const BoxWrapper = ({
  children,
  className = '',
  paddingClassName = 'px-4 md:px-5 lg:px-6',
}) => {
  return (
    <div
      className={`flex lg:mx-auto lg:max-w-7xl ${className} ${paddingClassName}`}
    >
      {children}
    </div>
  );
};

export default BoxWrapper;
