export const MenuDropdownTypes = {
  list: {
    row: 'list-row',
    col: 'list-col',
  },
  mega: {
    row: 'mega-row',
    col: 'mega-col',
  },
};
